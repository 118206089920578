.Topic-container {
  position: relative;
  max-width: 480px;
  height: 100%;
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.not-scrollable {
  overflow: hidden;
  overscroll-behavior-y: none;
}